import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import usdt from "./usdt.png"
import btc from "./btc.png"
import Toast from "./toast"
import man from "./man.png"
import { ApiRequest } from "./request";



export default function Register({ isLogged }) {
    const history = useHistory()
    useEffect(() => {
        isLogged && history.push("/dashboard")
    }, [])

    const ref1 = useRef("")
    const ref2 = useRef("")
    const ref3 = useRef("")
    const ref4 = useRef("")
    const [loading, setLoading] = useState(false)

    async function gainAccess() {
        const a = ref1.current.value
        const b = ref2.current.value
        const c = ref3.current.value
        const d = ref4.current.value
        if(a !== "" && b !== "" && c !== "" && d !== "") {
            setLoading(true)
            const res = await ApiRequest("post", "register.php", {
                fullname: a,
                email: b,
                pass: c,
                phone: d,
            })
            if(res?.status === true) {
                await ApiRequest("post", "mailme.php", {
                    email: b,
                    fname: a
                })
                Toast(res.message, "success")
                setTimeout(function() {
                    history.push("/login")
                }, 1500)
            }
            else {
                Toast(res?.message, "error")
            }
            setLoading(false)
        }
        else {
            Toast("Kindly fill all the input fields", "error")
        }
    }

    return (
        <Flex w="100%" justify="center" py="60px" minH="60vh" bg="linear-gradient(to right, #000914, #092a54)">
            <Flex bg="#fff" boxShadow="0rem 0rem 10rem 0rem rgba(72,240,179,0.188)" py={["10", "12"]} w={["90%", "630px"]} px={["4", "8"]} direction="column" data-aos="fade-up">
                <Flex>
                    <Image src={man} w="60px" h="60px" />
                    <Text ml="3">
                        <Text fontSize={["26px", "32px"]} fontWeight="700">Gain Access</Text>
                        <Text color="slategray" fontSize={["13px", "14px"]}>Kindly fill in the details to gain premium access</Text>
                    </Text>
                </Flex>

                <Text fontSize="20px" fontWeight="600" mt="6">Step 1:</Text>
                <Text color="slategray" fontSize="14px" mb="3">Personal Information</Text>

                
                <Flex w='100%'><input type="text" style={{ width: "100%", border: "1px solid #044eb0", borderRadius: "30px", padding: "12px 20px"}} placeholder="Enter your full name" ref={ref1} /></Flex>

                <Flex mt="3" w='100%'><input type="email" style={{ width: "100%", border: "1px solid #044eb0", borderRadius: "30px", padding: "12px 20px"}} placeholder="Enter your email" ref={ref2} /></Flex>

                <Flex mt="3" w='100%'><input type="password" style={{ width: "100%", border: "1px solid #044eb0", borderRadius: "30px", padding: "12px 20px"}} placeholder="Enter a password" ref={ref3} /></Flex>

                
                <Text fontSize="20px" fontWeight="600" mt="8">Step 2:</Text>
                <Text fontSize="20px" fontWeight="600">$300 one time Entry Fee</Text>
                <Text color="slategray" fontSize="14px" mb="5" mt="2">Pay Entry Fee. Select one of the crypto payment options either BTC or USDT (ERC 20). Contact our live chat after payment to verify your payment.</Text>

                <Flex borderRadius="8px" border="1px solid rgb(200,200,200)" px="2" py="2" align="center" cursor="pointer" onClick={() => {
                    navigator.clipboard.writeText("1BPN6Wofy4niNSp89iG8wwZS6MY4S4Nu4u")
                    Toast("BTC wallet copied to your clipboard", "success")
                }}>
                    <Flex flex="1" align="center">
                        <Image src={btc} w="30px" h="30px" />
                        <Text ml="3" textDecoration="underline" wordBreak="break-all" fontSize={["12px", "15px"]}>1BPN6Wofy4niNSp89iG8wwZS6MY4S4Nu4u</Text>
                    </Flex>
                    <Flex ml="2" w="35px" h="35px" fontSize="15px" cursor="pointer" border="1px solid #044eb0" justify="center" align="center" color="#044eb0" borderRadius="8px"><i className="mdi mdi-content-copy"></i></Flex>
                </Flex>

                <Flex mt="3" borderRadius="8px" border="1px solid rgb(200,200,200)" px="2" py="2" align="center" cursor="pointer" onClick={() => {
                    navigator.clipboard.writeText("0x90aF942Ead2BdfA84aFD4D463D2747C2581b0bB4")
                    Toast("USDT wallet copied to your clipboard", "success")
                }}>
                    <Flex flex="1" align="center">
                        <Image src={usdt} w="30px" h="30px" />
                        <Text ml="3" textDecoration="underline" wordBreak="break-all" fontSize={["12px", "15px"]}>0x90aF942Ead2BdfA84aFD4D463D2747C2581b0bB4 (ERC 20)</Text>
                    </Flex>
                    <Flex ml="2" w="35px" h="35px" fontSize="15px" cursor="pointer" border="1px solid #044eb0" justify="center" align="center" color="#044eb0" borderRadius="8px"><i className="mdi mdi-content-copy"></i></Flex>
                </Flex>

                <Flex mt="3" w='100%'><input type="tel" style={{ width: "100%", border: "1px solid #044eb0", borderRadius: "30px", padding: "12px 20px"}} placeholder="Enter your phone number e.g: +1234647" ref={ref4} /></Flex>

                <Flex mt="5" w="100%" justify="center" cursor="pointer" borderRadius="30px" padding="12px 28px" bg="#044eb0" color="#fff" _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => gainAccess()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey"/> : <><Text mr="2"><i className="mdi mdi-account"></i></Text>Register</>}</Flex>

                <Text mt="8" as="span" textAlign="center" fontSize={["14px", "15px"]}>Already have an account? <Text ml="2" fontWeight="600" color="#044eb0" cursor="pointer" _hover={{ textDecoration: "underline" }} as="span" onClick={() => history.push("/login")}>Sign In</Text></Text>
            </Flex>
        </Flex>
    )
}