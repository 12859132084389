import { useState, useEffect } from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import cover1 from "./cover1.png"
import vid1 from "./vid2.mp4"
import vid2 from "./vid4.mp4"
import cover2 from "./cover2.jpg"
import cover3 from "./cover3.jpg"
import cover4 from "./cover4.jpg"
import cover5 from "./cover5.jpg"
import { Accordion, AccordionItem as NewItem } from '@szhsin/react-accordion'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import man1 from "./man1.avif"
import man2 from "./man2.avif"
import woman1 from "./woman1.jpg"
import woman2 from "./woman2.jpg"



export default function Landing() {
    const history = useHistory()
    const AccordionItem = ({ header, ...rest }) => (
        <NewItem
          {...rest}
          header={
            <Flex align="center" w="100%" mb={["-2", "0"]} color="#fff">
              <Text className="font" fontSize={["16px", "18px"]} fontWeight="400">{header}</Text>
              <i className='mdi mdi-chevron-down chevron-down' style={{ fontSize: '25px' }}></i>
            </Flex>
          }
        />
    )

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" px={["5", "8%"]} justify="space-between" align="center" direction={["column", "row"]}>
                <Flex w={["100%", "50%"]} direction="column" data-aos="fade-right">
                    <Text fontWeight="bold" fontSize={["30px", "45px"]} mt={["60px", "0"]}>ClearValue Tax</Text>
                    <Text color="slategray" mt="2" textAlign="justify" fontSize={["13px", "15px"]}>Financial <Text color="#044eb0" as="span">freedom</Text> is not about having unlimited wealth; it's about having the <Text color="#044eb0" as="span">freedom</Text> to make choices that align with your values and passions without being constrained by money.</Text>

                    <Flex align="center" mt={["6", "8"]} borderTop="1px solid rgb(200,200,200)" pt="8">         
                        <Flex cursor="pointer" borderRadius="30px" padding="10px 28px" bg="#044eb0" color="#fff" _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => history.push("/register")}><Text mr="2"><i className="mdi mdi-account-circle"></i></Text>Begin Today</Flex>

                        <Flex ml={["2", "4"]} cursor="pointer" borderRadius="30px" padding="10px 28px" bg="#044eb0" color="#fff" _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => {
                            const element = document.getElementById('aboutArea')
                            return window.scrollTo({
                            top: element ? element.offsetTop - 100 : 0,
                            behavior: 'smooth',
                            })
                        }}>About<Text ml="2"><i className="mdi mdi-information-outline"></i></Text></Flex>
                    </Flex>
                    <Flex w="100%" border="2px solid #02c902" borderRadius="8px" mt="6">
                        <Flex w="80%" bg="#02c902" justify="center" fontSize="12px" fontWeight="600" color="#fff" align="center" py="1px">82% Full</Flex>
                    </Flex>
                    <Flex mt="3" fontSize="30px" fontWeight="600" align="center">
                        <Text mr={["auto", "12"]}>20K<Text fontSize="11px" fontWeight="400" color="slategray">Total Investors Spot</Text></Text>
                        <Text>12K+<Text fontSize="11px" fontWeight="400" color="slategray">Current Investors</Text></Text>
                    </Flex>
                </Flex>
                <Flex w={["70%", "40%"]} mt={["7", "0"]} data-aos="fade-left">
                    <Image w="100%" src={cover1} />
                </Flex>
            </Flex>


            <Flex w="100%" bg="#001024" color="#fff" px={["5", "20%"]} py={["70px", "80px"]} direction="column">
                <Text textAlign="center" fontSize={["20px", "24px"]} fontWeight="500" px={["2", "10%"]} data-aos="fade-down">
                    <Text>Greetings,</Text>

                    My name is Brian Kim. 
                    My mission is to help you obtain financial freedom. 
                    I provide helpful information so you can stay informed and make better decisions
                </Text>
                <Flex boxShadow="0rem 0rem 10rem 0rem rgba(72,240,179,0.188)" w="100%" justify="center" mt="8" data-aos="fade-up">
                    <video controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px"}} src={vid1}></video>
                </Flex>
                <Flex mt="2" w="100%" justify="center" cursor="pointer" borderRadius="30px" padding={["9px 20px", "10px 28px"]} bg="#044eb0" color="#fff" _hover={{ bg: "rgba(6, 95, 252, .8)" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => history.push("/register")} fontSize={["13px", "15px"]}><Text mr="2"><i className="mdi mdi-arrow-right"></i></Text>Join Us Now for only $300 as Entry fee</Flex>
            </Flex>

            
            <Flex w="100%" bg="#fff" px={["5", "20%"]} py={["70px", "80px"]} direction="column" id="aboutArea">
                <Text textAlign="center" fontSize={["20px", "28px"]} fontWeight="600" data-aos="fade-down">5 solid benefits<Text>why you should invest with ClearValue Tax</Text></Text>
                <Flex align="center" justify="center" w="100%" color="orange" mt="5" fontSize="30px" data-aos="fade-up">
                    <Text mr="2"><i className="mdi mdi-star-circle"></i></Text>
                    <Text mr="2"><i className="mdi mdi-star-circle"></i></Text>
                    <Text mr="2"><i className="mdi mdi-star-circle"></i></Text>
                    <Text mr="2"><i className="mdi mdi-star-circle"></i></Text>
                    <Text><i className="mdi mdi-star-circle"></i></Text>
                </Flex>
                <Flex direction="column" mt="12" borderLeft="1px solid #000" ml={["15px", "0"]}>
                    {
                        [{name: "Expert Advice", sub: "Membership grants access to expert opinions and advice. These are seasoned professionals who have spent years studying the market trends. Their experience and understanding of the investment market can be invaluable in making informed investment decisions"},{name: "Exclusive Information", sub: "access to privileged, real-time information about stocks, bonds, and other investment opportunities. This insider information can give you a significant advantage in making wise investment decisions that will yield high returns"},{name: "Networking Opportunities", sub: "Membership allows you to meet and network with like-minded investors. This can be a great opportunity to learn from others' experiences, share ideas and gain different perspectives"},{name: "Risk Management", sub: "Investment comes with its fair share of risks. Being part of my club you learn how to manage risks effectively. The club provides resources and tools to help you understand risk factors and how to mitigate them"},{name: "Continuous Learning", sub: "The world of investment is dynamic and ever-evolving. Membership continuous learning opportunities through seminars, webinars, and workshops. This helps you stay updated with the latest trends in the investment world"}].map((item, index) => (
                            <Flex w="100%" key={index} align="flex-start" mb={["8", "12"]} data-aos="fade-up">
                                <Flex bg="#fff" w={["45px", "60px"]} h={["45px", "60px"]} justify="center" align="center" fontWeight="700" border="1px solid #000" borderRadius="100%" ml={["-22px", "-30px"]} fontSize={["18px", "20px"]}>0{index + 1}</Flex>
                                <Flex flex="1" direction="column" ml="4" mt="1">
                                    <Text fontWeight="600" fontSize={["15px", "17px"]}>{item.name}</Text>
                                    <Text fontSize={["13px", "14px"]} color="slategray" textAlign="justify">{item.sub}</Text>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>

            <Flex bg="rgba(204, 144, 4, .05)" px={["5", "8%"]} align="center" justify="space-between" direction={["column", "row"]} py={["12", "0"]}>
                <Flex w={["100%", "45%" ]}backgroundImage={cover2} h={["300px", "550px"]} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center" data-aos="fade-up"></Flex>
                <Flex w={["100%", "47%"]} mt={["8", "0"]} direction="column" data-aos="zoom-in-up">
                    <Text fontSize={["21px", "24px"]} fontWeight="700">Lithium Battery Investment</Text>
                    <Text color="slategray" mt="2" textAlign="justify" fontSize={["13px", "15px"]}>We start by conducting thorough market research to understand the current trends, growth projections, and competitive landscape of the lithium battery industry. We look for reports and analysis from reputable sources.  Considering diversifying our investment within the lithium battery sector. This might include investing in lithium mining companies, battery manufacturers, or companies involved in EV production.  Keeping an eye on economic factors that can affect the lithium battery market, such as government policies, subsidies for EVs, and global supply chain dynamics. Sustainability is a significant driver in the EV and battery industry</Text>
                    <Flex mt={["6", "8"]}><Flex justify="center" cursor="pointer" borderRadius="30px" padding="10px 28px" bg="#044eb0" color="#fff" _hover={{ bg: "rgba(6, 95, 252, .8)" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => history.push("/register")}><Text mr="2"><i className="mdi mdi-arrow-right"></i></Text>Invest Now </Flex></Flex>
                </Flex>
            </Flex>




            <Flex w="100%" px={["5", "12%"]} py={["70px", "100px"]}direction="column">
                <Text fontSize={["24px", "28px"]} fontWeight="600" data-aos="fade-down">What do i get access to?</Text>
                <Text mt={["1", "0"]} textAlign="justify" color="slategray" fontSize={["13px", "15px"]} pr={["0", "39%"]} data-aos="fade-down">Membership clubs offer various benefits such as discounts, access to exclusive events, specialized services, networking opportunities, and unique member-only perks, depending on your level of investment</Text>

                <Flex w="100%" align="baseline" justify="space-between" mt={["8", "60px"]} direction={["column", "row"]}>
                    {
                        [{name: "Discounts", body: ["Scale from Zero to $10k/month as fast as possible", "Master the skills you need to maximize your income"], dp: cover3}, {name: "Networking Opportunities", body: ["Mentors are hyper-successful experts in their fields", "Get mentored every step of your journey and connect with 12k+ members"], dp: cover4}, {name: "Unique Member-only Perks", body: ["Share and receive the knowledge you need to maximize your income", "Get uptimum ROI's"], dp: cover5}].map((item, index) => (
                            <Flex w={["100%", "31.5%"]} mb={["6", "0"]} key={index} direction="column" borderRadius="8px" boxShadow="rgba(0, 0, 0, 0.08) 0px 1px 10px 4px" _hover={{ boxShadow: "rgba(4, 78, 176, 0.2) 0px 1px 10px 4px" }} transition="300ms ease-in-out" data-aos="zoom-in-up">
                                <Flex w="100%" backgroundImage={item.dp} h="250px" backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center"></Flex>
                                <Flex w="100%" direction="column" p="6">
                                    <Text mb="5" fontWeight="600">{item.name}</Text>
                                    <Flex direction="column" color="rgb(120,120,120)">
                                        {
                                            item.body.map((item, index) => (
                                                <Flex align="center" color="slategray" key={index} mb="3">
                                                    <i className="mdi mdi-check-all" style={{ fontSize: "20px" }}></i>
                                                    <Flex flex="1" ml="3" fontSize="13px">{item}</Flex>
                                                </Flex>
                                            ))
                                        }
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>



            <Flex w="100%" bg="rgb(250,250,252)" px={["5", "10%"]} py={["70px", "80px"]} direction="column">
                <Text textAlign="center" fontSize={["24px", "28px"]} fontWeight="600">Investment &amp; Mentorship Roadmap</Text>
                <Flex w='100%' justify="space-between" mt={["8", "60px"]} align="center" direction={["column-reverse", "row"]}>
                    <Flex w={["100%", "40%"]} direction="column" mt={["8", "0"]}>
                        {
                            ["Market leading investment platform", "Scalable to a 8-figure income", "Location independent", "Completely online", "Integration of AI technology for market analysis"].map((item, index) => (
                                <Flex w="100%" key={index} align="flex-start" mb="4" data-aos="fade-up">
                                    <Flex bg="#fff" w="40px" h="40px" justify="center" align="center" fontWeight="700" border="1px solid #000" borderRadius="6px" fontSize="18px"><i className="mdi mdi-format-list-checks"></i></Flex>
                                    <Flex flex="1" direction="column" ml="4" mt="1">
                                        <Text fontSize={["14px", "15px"]}>{item}</Text>
                                    </Flex>
                                </Flex>
                            ))
                        }
                    </Flex>
                    <Flex w={["100%", "55%"]} direction="column">
                        <video controls playsInline style={{ background: "#000", width: "100%", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 10px 4px"}} src={vid2}></video>
                    </Flex>
                </Flex>
            </Flex>



            <Flex w="100%" px={["5", "12%"]} py={["70px", "80px"]} direction="column" bg="linear-gradient(to right, #000914, #092a54)">
                <Text fontSize={["20px", "30px" ]} fontWeight="700" mt="2" textAlign="center" color="#fff" data-aos="fade-down">Frequently Asked Questions</Text>
                <Text textAlign="center" px={["2", "20%"]} fontSize={["13px", '15px']} color="rgb(240,240,240)" mb="8" data-aos="fade-down" fontWeight="300">Our extensive network of carriers and partners ensures efficient investment solutions, minimizing costs while maximizing returns and speed</Text>
                <Flex direction="column" w="100%" px={["2", "10%"]}>
                    <Accordion transition transitionTimeout={1000}>
                        <AccordionItem header="What types of investments are available?">There are various types of investments, including stocks, bonds, real estate, mutual funds, exchange-traded funds (ETFs), commodities, and more
                        </AccordionItem>
                        <AccordionItem header="What is the risk associated with investments?">All investments carry some level of risk. The type and degree of risk depend on the investment vehicle and market conditions. Diversification and a long-term perspective can help manage risk
                        </AccordionItem>
                        <AccordionItem header="How can I start investing?">By simply registering an account and contecting the live support.
                        </AccordionItem>
                        <AccordionItem header="What is the difference between stocks and bonds?">Stocks represent ownership in a company, while bonds are debt securities representing loans made to governments or corporations. Stocks offer potential for capital appreciation, while bonds provide regular interest payments.
                        </AccordionItem>
                        <AccordionItem header="Why should I consider investing in the lithium battery industry?">Investing in the lithium battery sector can be attractive due to the growing demand for electric vehicles (EVs), renewable energy storage, and portable electronics
                        </AccordionItem>
                    </Accordion>
                </Flex>
            </Flex>


            <Flex w="100%" px={["5", "20%"]} direction="column" py={["70px", "80px"]}>
                <Text textAlign="center" fontSize={["26px", "28px"]} fontWeight="600" mb="12" data-aos="fade-down">Our Members Testimonials</Text>
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showStatus={true}
                    showIndicators={false}
                    swipeable={true}
                    showArrows={true}
                    showThumbs={false}
                    stopOnHover={false}
                >
                    {
                        [{name: "Sarah Thompson", sub: "I've been using this investment platform for years, and it's been a game-changer for my financial goals. The user-friendly interface, educational resources, and expert guidance have made investing a breeze."},{name: "Mark Rodriguez", sub: "I'm relatively new to investing, but this platform has made the process easy to understand. The customer support team is incredibly responsive and patient in answering my questions."},{name: "Emily Chen", sub: "I was hesitant about lithium battery investments at first, but the resources on this website convinced me otherwise. It's clear they are experts in the field."},{name: "Michael Turner", sub: "During the recent market volatility, the insights and market analysis provided by this platform helped me navigate the turbulence. I feel more confident in my investment decisions."}].map((item, index) => (
                            <div>
                                <Flex key={index} w="100%" direction="column" align="center" px={["2", "15%"]}>
                                    <Flex w="120px" h="120px" backgroundImage={index === 0 ? woman1 : index === 1 ? man1 : index === 2 ? woman2 : man2} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center" borderRadius="100%"></Flex>
                                    <Text textAlign="center" fontSize={["15px", "18px"]} mt="6">{item.sub}</Text>
                                    <Text textAlign="center" mt="2" fontWeight="700" fontSize={["14px", "15px"]}> - {item.name}</Text>
                                </Flex>
                            </div>
                        ))
                    }
                </Carousel>
            </Flex>


            <Flex w="100%" px={["5", "15%"]} py="60px" borderTop="1px solid rgb(200,200,200)">
                <Flex w="100%" direction="column" boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 10px 4px" px={["5", "12"]} py="12" borderRadius="12px" data-aos="fade-up">
                    <Text textAlign="center" fontWeight="600" fontSize="28px" color="#044eb0">Get Full Access</Text>
                    <Text textAlign="center" fontWeight="600" fontSize="28px" color="#044eb0"><Text as="span" fontWeight="300">Entry Fee:</Text> $300</Text>

                    <Text mt="5" px={["0", "20%"]} textAlign="center" color="slategray" fontSize="14px">Spots are almost filled up with a total number of <Text as="span" color="#044eb0" fontSize="20px" fontWeight="600" mx="1">20K</Text> members, <Text as="span" color="#044eb0" fontSize="20px" fontWeight="600" mx="1">12K+</Text> current memebrs and <Text as="span" color="#044eb0" fontSize="20px" fontWeight="600" mx="1">20%</Text> slot available</Text>

                    <Flex mt="6" w="100%" borderRadius="12px" border="1px solid #044eb0" padding="10px"><Flex w="100%" justify="center" cursor="pointer" borderRadius="30px" padding="10px 28px" bg="#044eb0" color="#fff"  _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => history.push("/register")} fontSize={["13px", "15px"]}><Text mr="2"><i className="mdi mdi-arrow-right"></i></Text>Continue Entry</Flex></Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}