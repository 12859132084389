import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import founder from "./founder.jpg"
import Toast from "./toast";



export default function Footer() {
    const history = useHistory()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const ref1 = useRef("")

    return (
        <Flex w='100%' align="center" justify="space-between" borderTop={location.pathname.includes("dashboard") ? "none" : "1px solid rgb(200,200,200)"} pt={["50px", "60px"]} pb={["30px", "60px"]} px={["5", "8%"]} direction={["column", "row"]}>
            <Flex w={["100%", "28%"]} align="center">
                <Flex w="100px" h="100px" backgroundImage={founder} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center" borderRadius="100%"></Flex>
                <Text ml="3">
                    <Text fontWeight="600" fontSize="18px">Brian Kim</Text>
                    <Text color="slategray" fontSize="13px">Founder ClearValue Tax</Text>
                    <Flex align="center" color="#044eb0">
                        <Text fontSize="15px"><i className="mdi mdi-email"></i></Text>
                        <Text ml="2" fontSize="13px" color="#044eb0" cursor="pointer" onClick={() => window.open("mailto: brian@clearvaluetax.info")} _hover={{ textDecoration: "underline" }}>brian@clearvaluetax.info</Text>
                    </Flex>
                </Text> 
            </Flex>

            <Flex w={["100%", "32%"]} mt={["8", "0"]} direction="column" display={["none", "flex"]}>
                <Text fontWeight="600" fontSize="18px" mb={["1", "2"]}>Contact</Text>
                <Flex align="center" color="#044eb0">
                    <Text fontSize="20px"><i className="mdi mdi-email"></i></Text>
                    <Text ml="2" cursor="pointer" onClick={() => window.open("mailto: info@clearvaluetax.top")} _hover={{ textDecoration: "underline" }}>info@clearvaluetax.top</Text>
                </Flex>
                <Text mt="3" color="slategray" display={["none", "block"]}>&copy; 2023 ClearValue Tax. All Rights Reserved</Text>
            </Flex>

            <Flex w={["100%", "30%"]} mt={["8", "0"]} direction="column">
                <Text fontWeight="600" fontSize="18px" mb="2">Brian Kim's Contact List</Text>
                <Flex w='100%'><input type="email" style={{ width: "100%", border: "1px solid #044eb0", borderRadius: "30px", padding: "10px 20px"}} placeholder="Enter your email" ref={ref1} /></Flex>

                <Flex mt="2" w="100%" justify="center" cursor="pointer" borderRadius="30px" padding="10px 28px" bg="#044eb0" color="#fff" _hover={{ bg: "rgba(6, 95, 242, .2)", color: "#000" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => {
                    if(ref1.current.value !== "") {
                        setLoading(true)
                        setTimeout(function() {
                            Toast("You have been added to Brian Kim's contact list", "success")
                            setLoading(false)
                            ref1.current.value = ""
                        }, 1500)
                    }
                    else {
                        Toast("Kindly enter your email", "error")
                    }
                }}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <><Text mr="2"><i className="mdi mdi-arrow-right"></i></Text>Subscribe</>}</Flex>
            </Flex>

            <Flex w={["100%", "32%"]} mt={["8", "0"]} direction="column" display={["flex", "none"]}>
                <Text fontWeight="600" fontSize="18px" mb={["1", "2"]}>Contact</Text>
                <Flex align="center" color="#044eb0">
                    <Text fontSize="20px"><i className="mdi mdi-email"></i></Text>
                    <Text ml="2" cursor="pointer" onClick={() => window.open("mailto: info@clearvaluetax.top")} _hover={{ textDecoration: "underline" }}>info@clearvaluetax.top</Text>
                </Flex>
                <Text mt="3" color="slategray">&copy; 2023 ClearValue Tax. All Rights Reserved</Text>
            </Flex>
        </Flex>
    )
}